#landing {
	background: linear-gradient(-45deg,  #6d3ce2,  #23a6d5, #1dd3a9);
	background-size: 300% 300%;
	
	height: 100vh;
    
}

.animate-bg {
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 25%;
	}

	50% {
		background-position: 50% 50%;
	}

    100% {
		background-position: 0% 25%;
	}
}
